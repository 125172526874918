// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB6Wp2VDYRyK1L47u9JLtVkwjSf5cYEK1c",
    authDomain: "corkmusc-f2191.firebaseapp.com",
    projectId: "corkmusc-f2191",
    storageBucket: "corkmusc-f2191.firebasestorage.app",
    messagingSenderId: "762078232076",
    appId: "1:762078232076:web:7675d0e6f740ebd789f829",
    measurementId: "G-SFFSTNE5KZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

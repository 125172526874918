// src/pages/HomePage.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../styles/HomePage.css'; // Importing custom styles for the HomePage

function HomePage() {
  return (
    <div>
      
      {/* Static Image at the top */}
      <div className="top-image-container">
        <img
          className="top-image"
          src={require('../images/carousel/Old-Trafford-Manchester-United.jpg')} // Placeholder image
          alt="CorkMUSC Banner"
        />
      </div>

      {/* Information Sections About the Club */}
      <Container className="mt-5">
        {/* Section 1: About the Club */}
        <Row className="mb-5">
        <Col md={6}>
            <img
              src={require('../images/RedMUSC.jpg')}
              alt="About the club"
              className="img-fluid rounded imgMUSC"
            />
          </Col>
          <Col md={6}>
            <h2>About CorkMUSC</h2>
            <p>
              Cork Manchester United Supporters Club (Cork MUSC) is the official supporters club for Manchester United fans in Cork. 
              We bring together passionate supporters from all over the region to celebrate the love for the Reds. Established as an official 
              supporters club, we have strived to promote the team in every way we can.
            </p>
          </Col>
        </Row>

        {/* Section 2: Events and Activities */}
        <Row className="mb-5">
          <Col md={6}>
            <h2>Events and Activities</h2>
            <p>
              We organize match screenings, social events, and community activities to keep our members engaged and connected. 
              Join us for our upcoming events and experience the passion and excitement with fellow supporters. The official bar for Cork MUSC is The Blackpool Tavern.
            </p>
            <Button
              variant="primary"
              href="https://maps.app.goo.gl/KgFmuCc3puEFfRaC6"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3"
            >
              View on Google Maps
            </Button>
          </Col>
          <Col md={6}>
            <img
              src={require('../images/WhiteMUSC.jpg')}
              alt="Events and activities"
              className="img-fluid rounded imgMUSC"
            />
          </Col>
        </Row>

        {/* Section 3: Membership Benefits */}
        <Row className="mb-5">
        <Col md={6}>
            <img
              src={require('../images/blackMUSC.jpg')}
              alt="Membership benefits"
              className="img-fluid rounded imgMUSC"
            />
          </Col>
          <Col md={6}>
            <h2>Membership Benefits</h2>
            <p>
              Becoming a CorkMUSC member gives you exclusive access to match tickets, club merchandise, and special events. Get closer to the action and enjoy the perks of being part of a vibrant football fan community.
            </p>
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default HomePage;

// src/components/TicketEmailModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import moment from 'moment';

function TicketEmailModal({ show, onClose, onSubmit, match, defaultData }) {
  const [paymentDueDate, setPaymentDueDate] = useState('');
  const [ticketPrices, setTicketPrices] = useState({
    adult: '',
    o65: '',
    eighteenToTwenty: '',
    sixteenToSeventeen: '',
    u16: ''
  });
  const [stand, setStand] = useState('');
  const [rows, setRows] = useState('');

  // Use useEffect to initialize the form fields with default data if available
  useEffect(() => {
    if (defaultData) {
      setPaymentDueDate(defaultData.paymentDueDate || '');
      setTicketPrices({
        adult: defaultData.ticketPrices?.adult || '',
        o65: defaultData.ticketPrices?.o65 || '',
        eighteenToTwenty: defaultData.ticketPrices?.eighteenToTwenty || '',
        sixteenToSeventeen: defaultData.ticketPrices?.sixteenToSeventeen || '',
        u16: defaultData.ticketPrices?.u16 || ''
      });
      setStand(defaultData.stand || '');
      setRows(defaultData.rows || '');
    }
  }, [defaultData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in ticketPrices) {
      setTicketPrices((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === 'stand') {
      setStand(value);
    } else if (name === 'rows') {
      setRows(value);
    } else if (name === 'paymentDueDate') {
      setPaymentDueDate(value);
    }
  };

  const handleSubmit = () => {
    // Convert paymentDueDate to the required format
    const formattedPaymentDueDate = moment(paymentDueDate).format('dddd DD MMMM YYYY');
    console.log(formattedPaymentDueDate);

    // Pass data back to parent component
    onSubmit({
      matchId: match.id,
      matchName: match.name,
      paymentDueDate: formattedPaymentDueDate, // Send formatted paymentDueDate
      ticketPrices,
      stand,
      rows
    });
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Ticket Reservation Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="paymentDueDate">
            <Form.Label>Payment Due Date</Form.Label>
            <Form.Control
              type="date"
              name="paymentDueDate"
              value={paymentDueDate}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="adultPrice" className="mt-3">
            <Form.Label>Adult Ticket Price</Form.Label>
            <Form.Control
              type="number"
              name="adult"
              value={ticketPrices.adult}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="o65Price" className="mt-3">
            <Form.Label>Over 65 Ticket Price</Form.Label>
            <Form.Control
              type="number"
              name="o65"
              value={ticketPrices.o65}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="eighteenToTwentyPrice" className="mt-3">
            <Form.Label>18-20 Ticket Price</Form.Label>
            <Form.Control
              type="number"
              name="eighteenToTwenty"
              value={ticketPrices.eighteenToTwenty}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="sixteenToSeventeenPrice" className="mt-3">
            <Form.Label>16-17 Ticket Price</Form.Label>
            <Form.Control
              type="number"
              name="sixteenToSeventeen"
              value={ticketPrices.sixteenToSeventeen}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="u16Price" className="mt-3">
            <Form.Label>Under 16 Ticket Price</Form.Label>
            <Form.Control
              type="number"
              name="u16"
              value={ticketPrices.u16}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="stand" className="mt-3">
            <Form.Label>Stand</Form.Label>
            <Form.Control
              type="text"
              name="stand"
              value={stand}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="rows" className="mt-3">
            <Form.Label>Rows</Form.Label>
            <Form.Control
              type="text"
              name="rows"
              value={rows}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Send Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TicketEmailModal;

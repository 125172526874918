// src/pages/DashboardPage.js
import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Table, Row, Col, Modal, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';
import {
  fetchUsersByRole,
  fetchAllUsers,
  updateUserDetails,
  fetchMatches,
  fetchApplicationsForMatch,
  fetchApplicationsByUser,
  updateMatchDetails,
  fetchMatchDetails,
} from '../services/firebaseServices';
import '../styles/DashboardPage.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import TicketEmailModal from '../components/TicketEmailModal';
import axios from 'axios';

function DashboardPage() {
  const [tempUsers, setTempUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [matchApplications, setMatchApplications] = useState({});
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [filterRole, setFilterRole] = useState('');
  const [filterMuscPaid, setFilterMuscPaid] = useState('');
  const [filterRegistered, setFilterRegistered] = useState('');
  const USERS_PER_PAGE = 20;
  const [showApplicationsModal, setShowApplicationsModal] = useState(false);
  const [applicationsList, setApplicationsList] = useState([]);
  const [showUserApplicationsModal, setShowUserApplicationsModal] = useState(false);
  const [userApplications, setUserApplications] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showTicketEmailModal, setShowTicketEmailModal] = useState(false);
  const [selectedMatchForEmail, setSelectedMatchForEmail] = useState(null);

  useEffect(() => {
    const loadTempUsers = async () => {
      try {
        const users = await fetchUsersByRole('temp');
        setTempUsers(users);
      } catch (error) {
        console.error('Error fetching temp users:', error);
      }
    };

    const loadAllUsers = async () => {
      try {
        const users = await fetchAllUsers();
        setAllUsers(users);
      } catch (error) {
        console.error('Error fetching all users:', error);
      }
    };

    loadTempUsers();
    loadAllUsers();
    loadMatches(); // Load matches on initial load
  }, []);

  const loadMatches = async () => {
    try {
      const matchesData = await fetchMatches();
      // Sort matches by applicationOpenDate in ascending order
      matchesData.sort((a, b) => {
        return a.applicationOpenDate?.seconds - b.applicationOpenDate?.seconds;
      });
      setMatches(matchesData);

      // Load applications for each match
      const applicationsData = {};
      for (let match of matchesData) {
        const applications = await fetchApplicationsForMatch(match.id);
        applicationsData[match.id] = applications.length;
      }
      setMatchApplications(applicationsData);
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };

  const handleUpdateUser = async (userId, key, value) => {
    try {
      await updateUserDetails(userId, { [key]: value });
      // Update local state to reflect changes
      setTempUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userId ? { ...user, [key]: value } : user))
      );
      setAllUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userId ? { ...user, [key]: value } : user))
      );
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const handleResetMUSCPaid = async () => {
    try {
      const updates = allUsers.map(async (user) => {
        if (user.muscPaid !== 'no') {
          await updateUserDetails(user.id, { muscPaid: 'no' });
        }
      });
      await Promise.all(updates);

      // Update local state to reflect changes
      setTempUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, muscPaid: 'no' }))
      );
      setAllUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, muscPaid: 'no' }))
      );

      alert("All users' MUSC Paid status has been reset to 'No'.");
    } catch (error) {
      console.error('Error resetting MUSC Paid for all users:', error);
    }
  };

  const handleSendTicketEmail = async (match) => {
    try {
      // Fetch the full details of the selected match
      const matchDetails = await fetchMatchDetails(match.id); // This is a new function we will add to get the details
      setSelectedMatchForEmail(matchDetails); // Update state with full details of the selected match
      setShowTicketEmailModal(true); // Show the modal
    } catch (error) {
      console.error('Error fetching match details:', error);
      alert('Error fetching match details. Please try again.');
    }
  };
  

  const handleTicketEmailSubmit = async (data) => {
    // You would need to implement this function to save the ticket details and send the email.
    try {
      // Update the match document with ticket prices, stand, rows, etc.
      await updateMatchDetails(data.matchId, {
        ticketPrices: data.ticketPrices,
        stand: data.stand,
        rows: data.rows,
        paymentDueDate: data.paymentDueDate,
      });

      // Call the cloud function using an HTTP request to send the email
      const response = await axios.post('https://us-central1-corkmusc-f2191.cloudfunctions.net/sendTicketEmail', {
        matchId: data.matchId,
        matchName: data.matchName,
        paymentDueDate: data.paymentDueDate,
        ticketPrices: data.ticketPrices,
        stand: data.stand,
        rows: data.rows
      });

      if (response.status === 200) {
        alert("Ticket reservation emails have been sent.");
      } else {
        console.error("Error sending emails:", response.data);
        alert("Failed to send emails. Please try again.");
      }
      } catch (error) {
      console.error("Error updating match or sending emails:", error);
      alert("Failed to send emails. Please try again.");
      }
      };

      const getMatchStatus = (match) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set today's time to midnight (start of the day)
      
        const openDate = match.applicationOpenDate ? match.applicationOpenDate.toDate() : null;
        const closeDate = match.applicationCloseDate ? match.applicationCloseDate.toDate() : null;
      
        if (openDate && closeDate) {
          // Normalize openDate to remove time and match just the date
          const normalizedOpenDate = new Date(openDate);
          normalizedOpenDate.setHours(0, 0, 0, 0); // Set openDate time to midnight
      
          const normalizedCloseDate = new Date(closeDate);
          normalizedCloseDate.setHours(0, 0, 0, 0); // Set closeDate time to midnight
      
          if (today < normalizedOpenDate) {
            return 'Upcoming';
          } else if (today >= normalizedOpenDate && today <= normalizedCloseDate) {
            return 'Open';
          } else if (today > normalizedCloseDate) {
            return 'Closed';
          }
        }
        
        return 'Unknown';
      };

  const handleManageMatch = (match) => {
    setSelectedMatch({
      ...match,
      matchDate: match.matchDate ? match.matchDate.toDate() : null,
      applicationOpenDate: match.applicationOpenDate ? match.applicationOpenDate.toDate() : null,
      applicationCloseDate: match.applicationCloseDate ? match.applicationCloseDate.toDate() : null,
    });
    setShowMatchModal(true);
  };

  const handleViewApplications = async (matchId) => {
    try {
      const applications = await fetchApplicationsForMatch(matchId);
      setApplicationsList(applications);
      setShowApplicationsModal(true);
    } catch (error) {
      console.error('Error fetching applications for match:', error);
    }
  };

  const handleCloseApplicationsModal = () => {
    setShowApplicationsModal(false);
    setApplicationsList([]);
  };

  const handleCloseUserApplicationsModal = () => {
    setShowUserApplicationsModal(false);
    setUserApplications([]);
  };

  const handleCloseMatchModal = () => {
    setShowMatchModal(false);
    setSelectedMatch(null);
  };

  const handleMatchInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedMatch((prev) => ({
      ...prev,
      [name]: value ? new Date(value) : null,
    }));
  };

  const handleSaveMatchDetails = async () => {
    try {
      await updateMatchDetails(selectedMatch.id, {
        ...selectedMatch,
        matchDate: selectedMatch.matchDate,
        applicationOpenDate: selectedMatch.applicationOpenDate,
        applicationCloseDate: selectedMatch.applicationCloseDate,
      });
      setShowMatchModal(false);
      setSelectedMatch(null);
      loadMatches(); // Refresh the match list after saving
    } catch (error) {
      console.error('Error updating match details:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page after a search
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = showAllUsers ? allUsers : tempUsers;

    if (sortConfig.key) {
      sortableUsers = [...sortableUsers].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableUsers;
  }, [showAllUsers, allUsers, tempUsers, sortConfig]);

  const filteredUsers = sortedUsers.filter(
    (user) =>
      (!filterRole || user.role === filterRole) &&
      (!filterMuscPaid || user.muscPaid === filterMuscPaid) &&
      (!filterRegistered || user.registered === filterRegistered) &&
      (user.FName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.LName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.membershipNumber.toString().includes(searchQuery))
  );

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * USERS_PER_PAGE,
    currentPage * USERS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage * USERS_PER_PAGE < filteredUsers.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Container className="mt-4">
        {/* User Section */}
      <Row className="mb-3">
        <Col xs={12}>
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => {
              setShowAllUsers(!showAllUsers);
              setCurrentPage(1); // Reset to the first page
            }}
          >
            {showAllUsers ? 'Show New Users' : 'Show All Users'}
          </Button>
          {showAllUsers && (
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search by name or membership number"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </InputGroup>
          )}
          <Card className="shadow-sm">
            <Card.Header className="text-center">
              <h4>{showAllUsers ? 'All Users' : 'New Users'}</h4>
            </Card.Header>
            <Card.Body className="table-responsive">
              <div className="mb-3 d-flex justify-content-start align-items-center flex-wrap">
                <Form.Select
                  size="sm"
                  value={filterRole}
                  onChange={(e) => setFilterRole(e.target.value)}
                  className="me-2 mb-2"
                  style={{ width: '180px' }}
                >
                  <option value="">Filter by Role</option>
                  <option value="temp">Temp</option>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="season ticket holder">Season Ticket Holder</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={filterMuscPaid}
                  onChange={(e) => setFilterMuscPaid(e.target.value)}
                  className="me-2 mb-2"
                  style={{ width: '180px' }}
                >
                  <option value="">Filter by MUSC Paid</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={filterRegistered}
                  onChange={(e) => setFilterRegistered(e.target.value)}
                  className="me-2 mb-2"
                  style={{ width: '180px' }}
                >
                  <option value="">Filter by CorkMUSC Registered</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              {paginatedUsers && paginatedUsers.length > 0 ? (
                <>
                  <Table striped bordered hover size="sm" responsive>
                    <thead>
                      <tr>
                        <th onClick={() => handleSort('FName')}>Name</th>
                        <th onClick={() => handleSort('membershipNumber')}>Membership Number</th>
                        <th>MUSC Paid</th>
                        <th>CorkMUSC Registered</th>
                        <th>Role</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedUsers.map((user) => (
                        <tr key={user.id}>
                          <td>{user.FName} {user.LName}</td>
                          <td>{user.membershipNumber}</td>
                          <td>
                            <Button
                              variant={user.muscPaid === 'yes' ? 'success' : 'danger'}
                              onClick={() =>
                                handleUpdateUser(
                                  user.id,
                                  'muscPaid',
                                  user.muscPaid === 'yes' ? 'no' : 'yes'
                                )
                              }
                              size="sm"
                            >
                              {user.muscPaid === 'yes' ? 'Yes' : 'No'}
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant={user.registered === 'yes' ? 'success' : 'danger'}
                              onClick={() =>
                                handleUpdateUser(
                                  user.id,
                                  'registered',
                                  user.registered === 'yes' ? 'no' : 'yes'
                                )
                              }
                              size="sm"
                            >
                              {user.registered === 'yes' ? 'Yes' : 'No'}
                            </Button>
                          </td>
                          <td>
                            <Form.Select
                              value={user.role || 'temp'}
                              onChange={(e) =>
                                handleUpdateUser(user.id, 'role', e.target.value.toLowerCase())
                              }
                              size="sm"
                            >
                              <option value="temp">Temp</option>
                              <option value="user">User</option>
                              <option value="admin">Admin</option>
                              <option value="season ticket holder">Season Ticket Holder</option>
                            </Form.Select>
                          </td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              onClick={() => handleViewUserApplications(user.id)}
                            >
                              View Applications
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between mb-3">
                    <Button
                      variant="outline-primary"
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={handleNextPage}
                      disabled={currentPage * USERS_PER_PAGE >= filteredUsers.length}
                    >
                      Next
                    </Button>
                  </div>
                  <Button variant="danger" onClick={handleResetMUSCPaid}>
                    Reset All MUSC Paid to No
                  </Button>
                </>
              ) : (
                <p>No users available.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Match Overview Section */}
      <Row className="mb-4">
        <Col xs={12} lg={6}>
          <Card className="shadow-sm mb-4 mb-lg-0">
            <Card.Header className="text-center">
              <h4>Match Overview</h4>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey="open" id="match-overview-tabs" className="mb-3">
                <Tab eventKey="open" title="Open">
                  {matches.filter(match => getMatchStatus(match) === 'Open').length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Match Name</th>
                          <th>Applications</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches
                          .filter(match => getMatchStatus(match) === 'Open')
                          .map((match) => (
                            <tr key={match.id}>
                              <td>{match.name}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => handleViewApplications(match.id)}
                                >
                                  {matchApplications[match.id] || 0} Applications
                                </Button>
                              </td>
                              <td>
                                <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleManageMatch(match)}
                                >
                                <i className="bi bi-pencil-square"></i> {/* Bootstrap icon */}
                                </Button>

                                <Button variant="outline-success" size="sm" className="ms-2" onClick={() => handleSendTicketEmail(match)}>
                                <i className="bi bi-envelope"></i> {/* Bootstrap icon for email */}
                              </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No open matches available.</p>
                  )}
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming">
                  {matches.filter(match => getMatchStatus(match) === 'Upcoming').length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Match Name</th>
                          <th>Applications</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches
                          .filter(match => getMatchStatus(match) === 'Upcoming')
                          .map((match) => (
                            <tr key={match.id}>
                              <td>{match.name}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => handleViewApplications(match.id)}
                                >
                                  {matchApplications[match.id] || 0} Applications
                                </Button>
                              </td>
                              <td>
                                <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleManageMatch(match)}
                                >
                                <i className="bi bi-pencil-square"></i> {/* Bootstrap icon */}
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No upcoming matches available.</p>
                  )}
                </Tab>
                <Tab eventKey="closed" title="Closed">
                  {matches.filter(match => getMatchStatus(match) === 'Closed').length > 0 ? (
                    <Table striped bordered hover size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Match Name</th>
                          <th>Applications</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches
                          .filter(match => getMatchStatus(match) === 'Closed')
                          .map((match) => (
                            <tr key={match.id}>
                              <td>{match.name}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => handleViewApplications(match.id)}
                                >
                                  {matchApplications[match.id] || 0} Applications
                                </Button>
                              </td>
                              <td>
                                <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => handleManageMatch(match)}
                                >
                                <i className="bi bi-pencil-square"></i> {/* Bootstrap icon */}
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No closed matches available.</p>
                  )}
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card className="shadow-sm">
            <Card.Header className="text-center">
              <h4>Statistics Overview</h4>
            </Card.Header>
            <Card.Body>
              <p><strong>Total Registered Users:</strong> {allUsers.length}</p>
              <p><strong>Total Matches:</strong> {matches.length}</p>
              <p><strong>Total Pending Applications:</strong> {Object.values(matchApplications).reduce((a, b) => a + b, 0)}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Ticket Email Modal */}
      {selectedMatchForEmail && (
      <TicketEmailModal
        show={showTicketEmailModal}
        onClose={() => setShowTicketEmailModal(false)}
        onSubmit={handleTicketEmailSubmit}
        match={selectedMatchForEmail}
        defaultData={selectedMatchForEmail.ticketDetails || {
          paymentDueDate: selectedMatchForEmail.paymentDueDate,
          ticketPrices: selectedMatchForEmail.ticketPrices || {},
          stand: selectedMatchForEmail.stand,
          rows: selectedMatchForEmail.rows
        }} // Pass the ticket details as default data
      />
    )}

      {/* Match Details Modal */}
      <Modal show={showMatchModal} onHide={handleCloseMatchModal}>
        <Modal.Header closeButton>
          <Modal.Title>Match Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMatch ? (
            <Form>
              <Form.Group controlId="matchName">
                <Form.Label>Match Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedMatch.name || ''}
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="competitionType" className="mt-3">
                <Form.Label>Competition Type</Form.Label>
                <Form.Select
                  name="competitionType"
                  value={selectedMatch.competitionType || ''}
                  onChange={handleMatchInputChange}
                >
                  <option value="">Select Competition Type</option>
                  <option value="Premier League">Premier League</option>
                  <option value="European Cup">European Cup</option>
                  <option value="Domestic Cup">Domestic Cup</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="matchDate" className="mt-3">
                <Form.Label>Match Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="matchDate"
                  value={
                    selectedMatch.matchDate
                      ? new Date(selectedMatch.matchDate).toISOString().slice(0, 16)
                      : ''
                  }
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="applicationOpenDate" className="mt-3">
                <Form.Label>Application Open Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="applicationOpenDate"
                  value={
                    selectedMatch.applicationOpenDate
                      ? new Date(selectedMatch.applicationOpenDate).toISOString().slice(0, 16)
                      : ''
                  }
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="applicationCloseDate" className="mt-3">
                <Form.Label>Application Close Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="applicationCloseDate"
                  value={
                    selectedMatch.applicationCloseDate
                      ? new Date(selectedMatch.applicationCloseDate).toISOString().slice(0, 16)
                      : ''
                  }
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
              <Form.Group controlId="location" className="mt-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={selectedMatch.location || ''}
                  onChange={handleMatchInputChange}
                />
              </Form.Group>
            </Form>
          ) : (
            <p>Loading match details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMatchModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveMatchDetails}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Applications Modal */}
      <Modal show={showApplicationsModal} onHide={handleCloseApplicationsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Applications for Match</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {applicationsList.length > 0 ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Member Number</th>
                  <th>Full Name</th>
                  <th>Age Group</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {applicationsList.map((app, index) => (
                  <tr key={index}>
                    <td>{app.memberNumber}</td>
                    <td>{app.memberName}</td>
                    <td>{app.ageGroup}</td>
                    <td>
                      <Button variant="link" onClick={() => handleViewUserApplications(app.userID)}>
                        View User's Applications
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No applications available for this match.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseApplicationsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* User Applications Modal */}
      <Modal show={showUserApplicationsModal} onHide={handleCloseUserApplicationsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Applications for User {selectedUser}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userApplications.length > 0 ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Match Name</th>
                  <th>Date Applied</th>
                </tr>
              </thead>
              <tbody>
                {userApplications.map((app, index) => (
                  <tr key={index}>
                    <td>{app.matchName}</td>
                    <td>{new Date(app.appliedDate.seconds * 1000).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No applications found for this user.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserApplicationsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default DashboardPage;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import MatchPage from './pages/MatchPage.js'; // Correctly importing MatchPage component
import NavbarComponent from './components/NavbarComponent';
import ProfilePage from './pages/ProfilePage.js';
import DashboardPage from './pages/Dashboard.js';
import Footer from './components/Footer.js';
import './App.css'; // Import the CSS file for styles

function App() {
  return (
    <div className="app-container">
      <Router>
        <NavbarComponent />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/matches" element={<MatchPage />} /> {/* Correct usage */}
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

// src/components/AddMatchModal.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Timestamp } from 'firebase/firestore';

function AddMatchModal({ show, onHide, onAddMatch }) {
  const [matchName, setMatchName] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const [applicationOpenDate, setApplicationOpenDate] = useState('');
  const [applicationCloseDate, setApplicationCloseDate] = useState('');
  const [competitionType, setCompetitionType] = useState('Premier League');

  const handleAdd = () => {
    // Create a match object to save
    const newMatch = {
      name: matchName,
      matchDate: Timestamp.fromDate(new Date(matchDate)),
      applicationOpenDate: Timestamp.fromDate(new Date(applicationOpenDate)),
      applicationCloseDate: Timestamp.fromDate(new Date(applicationCloseDate)),
      competitionType,
    };

    onAddMatch(newMatch);
    onHide(); // Close the modal after adding match
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Match</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Match Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter match name"
              value={matchName}
              onChange={(e) => setMatchName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Match Date</Form.Label>
            <Form.Control
              type="datetime-local"
              value={matchDate}
              onChange={(e) => setMatchDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Applications Open Date</Form.Label>
            <Form.Control
              type="datetime-local"
              value={applicationOpenDate}
              onChange={(e) => setApplicationOpenDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Applications Close Date</Form.Label>
            <Form.Control
              type="datetime-local"
              value={applicationCloseDate}
              onChange={(e) => setApplicationCloseDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Competition Type</Form.Label>
            <Form.Select
              value={competitionType}
              onChange={(e) => setCompetitionType(e.target.value)}
            >
              <option value="Premier League">Premier League</option>
              <option value="European Cup">European Cup</option>
              <option value="Domestic Cups">Domestic Cups</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAdd}>
          Add Match
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMatchModal;

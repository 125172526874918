import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { setDoc, doc, getDocs, collection, query, where } from 'firebase/firestore';
import { Form, Button, Container, Row, Col, Tooltip, OverlayTrigger, Spinner, InputGroup } from 'react-bootstrap';

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [membershipNumber, setMembershipNumber] = useState('');
  const [membershipType, setMembershipType] = useState('Premium');
  const [error, setError] = useState('');
  const [isMembershipNumberUnique, setIsMembershipNumberUnique] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [matchDistribution, setMatchDistribution] = useState(false); // New state for checkbox
  const navigate = useNavigate();

  // Function to validate membership number uniqueness in real-time
  const checkMembershipNumberUnique = async (number) => {
    if (number.trim() === '') {
      setIsMembershipNumberUnique(null);
      return;
    }
    setIsLoading(true);
    try {
      const membersRef = collection(db, "members");
      const q = query(membersRef, where("membershipNumber", "==", number));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setIsMembershipNumberUnique(false);
        setError('Membership number is associated with another account. Please login with that account or contact admin.');
      } else {
        setIsMembershipNumberUnique(true);
        setError(''); // Clear error if membership number is unique
      }
    } catch (err) {
      console.error("Error checking membership number uniqueness:", err);
      setError('Error checking membership number. Please try again.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Validate the form based on field completion and membership number uniqueness
    if (
      firstName &&
      lastName &&
      email &&
      password &&
      dob &&
      membershipNumber &&
      isMembershipNumberUnique === true &&
      validateAge(dob)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [firstName, lastName, email, password, dob, membershipNumber, isMembershipNumberUnique]);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    // Calculate age based on the provided DOB
    if (!validateAge(dob)) {
      setError('Members 18 or under must be registered by an existing account holder in the user menu.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, "members", user.uid), {
        uid: user.uid,
        email: user.email,
        FName: firstName,
        LName: lastName,
        dob: dob,
        membershipNumber: membershipNumber,
        membershipType: membershipType,
        role: 'temp',  // Set the role to 'temp'
        matchDistribution: matchDistribution // Save matchDistribution status
      });

      console.log("User added to Firestore successfully.");
      navigate('/');
    } catch (err) {
      console.error("Error during registration:", err);
      setError('Failed to register. Please check your details.');
    }
  };

  const validateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age > 17;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Users cannot be registered with Forwarding membership.
    </Tooltip>
  );

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <div className="bg-white p-4 rounded shadow-sm">
            <h2 className="text-center mb-4" style={{ color: 'var(--bs-primary)' }}>Register</h2>
            <Form onSubmit={handleRegister}>
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formLastName" className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formDob" className="mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMembershipNumber" className="mb-3">
                <Form.Label>Membership Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={membershipNumber}
                    onChange={(e) => {
                      setMembershipNumber(e.target.value);
                      checkMembershipNumberUnique(e.target.value);
                    }}
                    required
                  />
                  {isLoading && (
                    <InputGroup.Text>
                      <Spinner animation="border" size="sm" />
                    </InputGroup.Text>
                  )}
                </InputGroup>
                {isMembershipNumberUnique === false && (
                  <Form.Text className="text-danger">
                    Membership number is associated with another account. Please login with that account or contact admin
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formMembershipType" className="mb-3">
                <Form.Label>Membership Type</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Select
                    value={membershipType}
                    onChange={(e) => setMembershipType(e.target.value)}
                    required
                    className="me-2"
                  >
                    <option value="Premium">Premium</option>
                    <option value="Full">Full</option>
                    <option value="Lite">Lite</option>
                  </Form.Select>
                  <OverlayTrigger
                    placement="right"
                    overlay={renderTooltip}
                  >
                    <span className="d-inline-block">
                      ⓘ
                    </span>
                  </OverlayTrigger>
                </div>
              </Form.Group>

              {/* New Checkbox for Match Distribution */}
              <Form.Group controlId="formMatchDistribution" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="I agree to receive emails related to match applications and notifications."
                  checked={matchDistribution}
                  onChange={(e) => setMatchDistribution(e.target.checked)}
                />
                <Form.Text className="text-muted">
                  By opting in you agree to receive emails when applications for a game open. You will also receive emails regarding any matches you have applied for.
                </Form.Text>
              </Form.Group>

              {error && <p className="text-danger text-center">{error}</p>}

              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-3"
                disabled={!isFormValid}
              >
                Register
              </Button>
            </Form>
            <p className="text-center mt-4">
              Already have an account? <Link to="/" style={{ color: 'var(--bs-primary)' }}>Login here</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterPage;

// src/components/NavbarComponent.js
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Assuming you have Firebase and Firestore initialized in firebase.js
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

function NavbarComponent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to control menu expand/collapse
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);

        // Fetch the user's first name and role from Firestore
        try {
          const userDoc = await getDoc(doc(db, 'members', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFirstName(userData.FName);
            setIsAdmin(userData.role === 'admin'); // Check if user is an admin
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setIsAuthenticated(false);
        setFirstName('');
        setIsAdmin(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Handle successful logout if needed
        setIsAuthenticated(false);
        setFirstName('');
        setIsAdmin(false);
        navigate('/');
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleNavLinkClick = () => {
    setExpanded(false); // Collapse the menu after clicking a link
  };

  return (
    <Navbar
      expand="lg"
      variant="dark"
      style={{ backgroundColor: '#DA291C' }}
      sticky="top"
      expanded={expanded} // Control navbar expanded state
    >
      <Container>
        <Row className="w-100 align-items-center">
          {/* Left Part: Logo and User's First Name */}
          <Col xs={4} className="d-flex align-items-center">
            <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
              <img
                src="/CorkMUSC.jpg"
                width="80"
                height="40"
                className="d-inline-block align-top"
                alt="CorkMUSC Logo"
              />
              {isAuthenticated && firstName && (
                <span className="text-white ms-2 d-none d-lg-block" style={{ fontSize: '1rem' }}>
                  Hi, {firstName}
                </span>
              )}
            </Navbar.Brand>
          </Col>

          {/* Center Part: CorkMUSC Text */}
          <Col xs={4} className="text-center">
            <span className="text-white fs-4">CorkMUSC</span>
          </Col>

          {/* Right Part: Menu Links */}
          <Col xs={4} className="text-end">
            <Navbar.Toggle 
              aria-controls="basic-navbar-nav" 
              onClick={() => setExpanded(expanded ? false : "expanded")} // Toggle menu open/close
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {!isAuthenticated ? (
                  // If not authenticated, show only the login button
                  <Nav.Link as={Link} to="/login" className="text-white" onClick={handleNavLinkClick}>
                    Login
                  </Nav.Link>
                ) : (
                  // If authenticated, show all menu links
                  <>
                    {isAdmin && (
                      <Nav.Link as={Link} to="/dashboard" className="text-white" onClick={handleNavLinkClick}>
                        Dashboard
                      </Nav.Link>
                    )}
                    <Nav.Link as={Link} to="/matches" className="text-white" onClick={handleNavLinkClick}>
                      Matches
                    </Nav.Link>
                    {/*<Nav.Link as={Link} to="/tickets" className="text-white" onClick={handleNavLinkClick}>
                      Tickets
                    </Nav.Link>*/}
                    <Nav.Link as={Link} to="/profile" className="text-white" onClick={handleNavLinkClick}>
                      Profile
                    </Nav.Link>
                    <Button
                      variant="outline-light"
                      onClick={() => {
                        handleLogout();
                        handleNavLinkClick(); // Close the menu after logout
                      }}
                      className="ms-3"
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
